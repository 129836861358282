export const jobsInfo = [
  {
    companyName: "Zemoga by Media.Monks",
    date: "Feb 2021 - Current day",
    location: "Bogotá - Medellin",
    responsibilities:
      "Sr. Front-end developer",
    technologies:
      "Javascript, Node, TypeScript, React, GraphQL NX suite, TestCafe, testing Library",
    practices: "Agile, Scrum, TDD, CI and others.",
    projects: "Confidential",
    clients: "Confidential",
    imgSrc:
      "https://pbs.twimg.com/profile_images/1351907393576312832/TWv0jYGL_400x400.jpg",
  },
  {
    companyName: "Globant",
    date: "Apr 2019 – Jan 2021",
    location: "Medellín",
    responsibilities:
      "Web UI developer",
    technologies: "React, Redux, AEM, Sass, Less, Azure DevOps and so forth.",
    practices: "Agile, Kanban, Scrum, TDD, CI and others.",
    projects:
      "EY Covid-19 Stimulus Response Tool, Sodimac Microblend Paint Solution and Rimac Insurance and Taxes Platform.",
    clients: "EY, Rimac, Sodimac and Protección.",
    imgSrc:
      "https://media-exp1.licdn.com/dms/image/C4E0BAQEyRlrQPqbyMw/company-logo_200_200/0?e=2159024400&v=beta&t=HCQrSkLs-UOxMUJztWHKhaHJYnys2s-x8PzMmPe_Vdo",
  },
  {
    companyName: "Tambourine",
    date: "2017 - Mar 2019",
    location: "Fort Lauderdale - Bogotá",
    responsibilities: "Sr. Front-end developer",
    technologies: "PHP, Jquery, MySql, Html, Sass, Less, Stylus, and so forth.",
    practices: "",
    projects:
      "EY Covid-19 Stimulus Response Tool, Sodimac Microblend Paint Solution and Rimac Insurance and Taxes Platform.",
    clients: "Marriott, Trump, Hilton, Charlestowne, BW, and Windham.",
    imgSrc:
      "https://media-exp1.licdn.com/dms/image/C4E0BAQE-f7PejplcDw/company-logo_200_200/0?e=2159024400&v=beta&t=cec2auItsm1_0OS2dsFQ5V4LxbAREr7DDmnVJGiTBRk",
  },
];

export const contactInfo = [
  {
    icon: "M17.95 528.854h71.861c9.914 0 17.95-8.037 17.95-17.951V196.8c0-9.915-8.036-17.95-17.95-17.95H17.95C8.035 178.85 0 186.885 0 196.8v314.103c0 9.913 8.035 17.951 17.95 17.951zM17.95 123.629h71.861c9.914 0 17.95-8.036 17.95-17.95V41.866c0-9.914-8.036-17.95-17.95-17.95H17.95C8.035 23.916 0 31.952 0 41.866v63.813c0 9.914 8.035 17.95 17.95 17.95zM525.732 215.282c-10.098-13.292-24.988-24.223-44.676-32.791-19.688-8.562-41.42-12.846-65.197-12.846-48.268 0-89.168 18.421-122.699 55.27-6.672 7.332-11.523 5.729-11.523-4.186V196.8c0-9.915-8.037-17.95-17.951-17.95h-64.192c-9.915 0-17.95 8.035-17.95 17.95v314.103c0 9.914 8.036 17.951 17.95 17.951h71.861c9.915 0 17.95-8.037 17.95-17.951V401.666c0-45.508 2.748-76.701 8.244-93.574 5.494-16.873 15.66-30.422 30.488-40.649 14.83-10.227 31.574-15.343 50.24-15.343 14.572 0 27.037 3.58 37.393 10.741 10.355 7.16 17.834 17.19 22.436 30.104 4.604 12.912 6.904 41.354 6.904 85.33v132.627c0 9.914 8.035 17.951 17.949 17.951h71.861c9.914 0 17.949-8.037 17.949-17.951V333.02c0-31.445-1.982-55.607-5.941-72.48s-10.992-31.959-21.096-45.258z",
    url: "https://www.linkedin.com/in/camilo-rivera-quintero-395266b3/",
    mailto: "",
    text: "LinkedIn",
  },
  {
    icon: "M256 0C115.39 0 0 115.39 0 256c0 119.988 84.195 228.984 196 256v-84.695c-11.078 2.425-21.273 2.496-32.55-.828-15.13-4.465-27.423-14.543-36.548-29.91-5.816-9.813-16.125-20.454-26.879-19.672l-2.636-29.883c23.254-1.992 43.37 14.168 55.312 34.23 5.305 8.922 11.41 14.153 19.246 16.465 7.575 2.23 15.707 1.16 25.184-2.187 2.379-18.973 11.07-26.075 17.637-36.075v-.015c-66.68-9.946-93.254-45.32-103.801-73.242-13.977-37.075-6.477-83.391 18.238-112.66.48-.571 1.348-2.063 1.012-3.106-11.332-34.23 2.476-62.547 2.984-65.55 13.078 3.866 15.203-3.892 56.809 21.386l7.191 4.32c3.008 1.793 2.063.77 5.07.543 17.372-4.719 35.684-7.324 53.727-7.558 18.18.234 36.375 2.84 54.465 7.75l2.328.234c-.203-.031.633-.149 2.035-.984 51.973-31.481 50.106-21.192 64.043-25.723.504 3.008 14.13 31.785 2.918 65.582-1.512 4.656 45.059 47.3 19.246 115.754-10.547 27.933-37.117 63.308-103.797 73.254v.015c8.547 13.028 18.817 19.957 18.762 46.832V512c111.809-27.016 196-136.012 196-256C512 115.39 396.61 0 256 0zm0 0",
    url: "https://github.com/contracamilo",
    mailto: "",
    text: "GitHub",
  },
  {
    icon: "M434.146 59.882H44.912C20.146 59.882 0 80.028 0 104.794v269.47c0 24.766 20.146 44.912 44.912 44.912h389.234c24.766 0 44.912-20.146 44.912-44.912v-269.47c0-24.766-20.146-44.912-44.912-44.912zm0 29.941c2.034 0 3.969.422 5.738 1.159L239.529 264.631 39.173 90.982a14.902 14.902 0 015.738-1.159zm0 299.411H44.912c-8.26 0-14.971-6.71-14.971-14.971V122.615l199.778 173.141c2.822 2.441 6.316 3.655 9.81 3.655s6.988-1.213 9.81-3.655l199.778-173.141v251.649c-.001 8.26-6.711 14.97-14.971 14.97z",
    url: "",
    mailto: "camilo.riveradev@gmail.com",
    text: "camilo.riveradev@gmail.com",
  },
];

export const practices = [
  {
    translation: "agile",
    text: "Agile",
    i18N: "PRACTICE_AGILE",
  },
  {
    translation: "kanban",
    text: "Kanban",
    i18N: "TECH_KANBAN",
  },
  {
    translation: "scrum",
    text: "Scrum",
    i18N: "TECH_SCRUM",
  },
  {
    translation: "tdd",
    text: "Test Driven Development",
    i18N: "TECH_TDD",
  },
  {
    translation: "ci",
    text: "Continuous integration and others",
    i18N: "TECH_CI",
  },
];

export const jobOneInfo = [
  {
    translation: "tech",
    text: "Technologies: React, Redux, AEM, Sass, Less, Azure DevOps and so forth.",
    i18N: "TECH_LIST",
  },
  {
    translation: "practices",
    text: "Practices: Agile, Kanban, Scrum, TDD, CI and others.",
    i18N: "PRACTICE_LIST",
  },
  {
    translation: "Projects",
    text: "Projects: EY Covid-19 Stimulus Response Tool, Sodimac Microblend Paint Solution and Rimac Insurance and Taxes Platform.",
    i18N: "PROJECT_LIST",
  },

  {
    translation: "Clients",
    text: "Clients: EY, Rimac, Sodimac and Protección.",
    i18N: "PROJECT_CLIENTS",
  },
];

export const jobTwoInfo = [
  {
    translation: "tech-two",
    text: "Technologies: PHP, Jquery, MySql, Html, Sass, Less, Stylus, and so forth.",
    i18N: "TECH_LIST_TWO",
  },

  {
    translation: "Clients-two",
    text: "Clients: Marriott, Trump, Hilton, Charlestowne, BW, and Windham.",
    i18N: "PROJECT_CLIENTS_TWO",
  },
];

export const jobThreeInfo = [
  {
    translation: "tech",
    text: "Technologies: Javascript, Node, React, TypeScript, GQL, TestCafe, Testing Library, NX suite and so forth.",
    i18N: "TECH_LIST_THREE",
  },
  {
    translation: "practices",
    text: "Practices: Agile, Scrum, TDD, CI and others.",
    i18N: "PRACTICE_LIST",
  },
  {
    translation: "Projects",
    text: "Confidential",
    i18N: "PROJECT_LIST_CONF",
  },

  {
    translation: "Clients",
    text: "Confidential",
    i18N: "PROJECT_LIST_CONF",
  },
];
