import React from 'react';
import Routes from './routes';

function App() {
  return (
    <main className="layout">
      <Routes />
    </main>
  );
}

export default App;

